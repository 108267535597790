@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	margin: 0;
	background-color: #111;
}

/* footer.css */

@keyframes slide {
	0% {
		left: 0;
	}
	100% {
		left: 100%;
	}
}

.animate-slide {
	animation: slide 4s linear infinite;
}
